import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SpeakingPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="intro">
      <div className="intro-container">
        <h2>Speaking</h2>
        <p>Since 2016, Preston has spoken about <strong>latex care</strong>, <strong>latex fetish practices</strong>, and <strong>the experience of people of color</strong> in the kink community.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <ul className="content-list">
          <li>
            <h3>Keynote: Challenge your biases the way your fetish challenges you</h3>
            <p>Secret Talent of Pets 2020</p>
            <p>Virtual</p>
            <p><small>October 17, 2020</small></p>
          </li>
          <li>
            <h3>POC State of the Union</h3>
            <p>Cleveland Leather Annual Weekend 2019</p>
            <p>Cleveland</p>
            <p><small>April 26, 2019</small></p>
          </li>
          <li>
            <h3>Keynote: Power in presence</h3>
            <p>International Olympus Leather 2018</p>
            <p>San Diego</p>
            <p><small>June 26, 2018</small></p>
          </li>
          <li>
            <h3>The Asian and Asian-American experience in kink and fetish</h3>
            <p>Cleveland Leather Annual Weekend 2018</p>
            <p>Cleveland</p>
            <p><small>April 28, 2018</small></p>
          </li>
          <li>
            <h3>Latex 201: Bondage for the rubber enthusiast</h3>
            <p>Cleveland Leather Annual Weekend 2017</p>
            <p>Cleveland</p>
            <p><small>April 28, 2017</small></p>
          </li>
          <li>
            <h3>Latex fetishism 101</h3>
            <p>DV8/Purple Passion</p>
            <p>New York</p>
            <p><small>October 23, 2016</small></p>
          </li>
        </ul>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default SpeakingPage
